import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Resources',
      to: '/get-started/for-developers/resources'
    }, {
      name: 'Canvas Kit Labs'
    }]} mdxType="Breadcrumbs" />
    <p>{`This is a group of work-in-progress components. Canvas Kit Labs is an incubator for new and
experimental components. Since we have a rather rigorous process for getting components in at a
production level, it can be valuable to make them available earlier while we continuously iterate on
the API, functionality and accessibility. The Labs components allow us to do that as needed.`}</p>
    <p>{`Note: components in Labs do not require a full design or accessibility review. Use them at your own
risk.`}</p>
    <p>{`Generally, Canvas Kit Labs components are promoted to
`}<a parentName="p" {...{
        "href": "/get-started/for-developers/resources/canvas-kit-preview"
      }}>{`Canvas Kit Preview`}</a>{` before being
promoted into the universal module `}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`. Essentially, Canvas Kit Labs is for
alpha components and Canvas Kit Preview is for beta components.`}</p>
    <h2 {...{
      "id": "breaking-changes"
    }}>{`Breaking Changes`}</h2>
    <p>{`Due to the nature of this bundle of components, we will be continuously iterating, likely making
breaking changes. The goal of this bundle is to work unconstrained by our design system's semantic
versioning. This means that `}<strong parentName="p">{`breaking changes can be deployed to Labs modules at any time and it
will not trigger a major version bump across our other modules`}</strong>{`. Regardless of the fact that we use
a fixed version number for all components, all changes/additions in this bundle will never result in
a major version bump across our component library.`}</p>
    <p><strong parentName="p">{`By consuming any of these modules, you acknowledge the potential complexity of updating and
ultimately performing a migration when components are promoted to a stable version.`}</strong></p>
    <p><strong parentName="p">{`These components are purely opt in`}</strong>{`, so they are not included in the main module
`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <ul>
      <li parentName="ul">{`Docs & examples`}</li>
      <li parentName="ul">{`Change announcements & migration strategies`}</li>
      <li parentName="ul">{`Breaking changes can happen at any time`}</li>
      <li parentName="ul">{`Updates or promotion to Canvas Kit Preview may require signficant refactoring`}</li>
      <li parentName="ul">{`Direct support when we have capacity, but we prioritize Preview and Main components first`}</li>
    </ul>
    <h2 {...{
      "id": "module-structure"
    }}>{`Module Structure`}</h2>
    <pre><code parentName="pre" {...{}}>{`/modules
  ...
  /labs-react
    /new-component
      /* Standard React component structure */
`}</code></pre>
    <h2 {...{
      "id": "creating-a-canvas-kit-labs-component"
    }}>{`Creating a Canvas Kit Labs Component`}</h2>
    <ol>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn create-component`}</inlineCode></li>
      <li parentName="ol">{`When asked `}<inlineCode parentName="li">{`What category should this component live in?`}</inlineCode>{`, select `}<inlineCode parentName="li">{`Labs (alpha)`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Your new module will be generated in accordance with the file structure above. It will get a
package name of `}<inlineCode parentName="li">{`@workday/canvas-kit-labs-react/<COMPONENT>`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`If you had the storybook server running, you may need to restart it.`}</li>
    </ol>
    <h2 {...{
      "id": "migrating-a-component-into-canvas-kit-labs"
    }}>{`Migrating a Component into Canvas Kit Labs`}</h2>
    <ol>
      <li parentName="ol">{`Copy it into the above module structure`}</li>
      <li parentName="ol">{`Add a warning to the README:`}
        <blockquote parentName="li">
          <undefined parentName="blockquote"><a {...{
              "href": "https://github.com/Workday/canvas-kit/tree/master/modules/labs-react/README.md"
            }}>{`
  `}<img parentName="a" {...{
                "src": "https://img.shields.io/badge/LABS-alpha-orange",
                "alt": "LABS: Alpha"
              }}></img>
            </a>{` This component is work in progress and currently in pre-release.`}</undefined>
        </blockquote>
      </li>
      <li parentName="ol">{`Update any necessary paths (links to storybook utils, tsconfig, etc.)`}</li>
      <li parentName="ol">{`Change the storybook paths to add a `}<inlineCode parentName="li">{`Labs`}</inlineCode>{` prefix (e.g. `}<inlineCode parentName="li">{`Labs/Menu/Default`}</inlineCode>{`)`}</li>
    </ol>
    <h2 {...{
      "id": "promoting-a-component-out-of-canvas-kit-labs"
    }}>{`Promoting a Component out of Canvas Kit Labs`}</h2>
    <ol>
      <li parentName="ol">{`Run `}<inlineCode parentName="li">{`yarn promote-component`}</inlineCode></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      